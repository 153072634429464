<template>
  <div class="safety-page">
    <div class="safety-banner"></div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">资讯动态</li>
          </ol>
        </nav>
        <div>
          <div class="safety-list">
            <div
              class="card"
              v-for="item in data.list"
              :key="item.identify"
              @click="toDetail(item)"
            >
              <div class="card-img-top">
                <img :src="item.cover" />
              </div>
              <div class="card-body">
                <div class="card-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <Pagination
            @change="pageChanged"
            :current="1"
            :pageSize="15"
            :hideOnSinglePage="true"
            :total="parseInt(data.total)"
            :showQuickJumper="false"
            :showTotal="false"
            placement="center"
          />
          <!-- <PageNav @pageChanged="pageChanged"
                   :max="calcMaxPage(data.total, 15)"
                   ref="page" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue";
import { articleList, articleDetail } from "@/network/API.js";
export default {
  components: {
    SearchBar,
    Pagination,
  },
  data() {
    return {
      data: {},
      pageNumber: 1,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList(page = this.pageNumber) {
      this.pageNumber = this.page;
      if (this.$refs.page) {
        this.$refs.page.pageNumber = page;
      }
      let data = await articleList({
        page: page,
        page_size: 15,
        show_total: 1,
        code: "news",
      });
      this.data = data;
    },
    async toDetail(item) {
      if (item.feed_id && item.finder_user_name) {
        //视频号
        this.$toast("暂不支持查看视频号");
        return;
      }
      let detail = await articleDetail(item.identify);
      if (detail.offiaccount_url) {
        window.location.href = detail.offiaccount_url;
        // window.open(detail.offiaccount_url)
        return;
      }
      if (detail.identify) {
        this.$router.push({
          name: "SafetyDetail",
          params: {
            id: detail.identify,
          },
        });
      }
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList(val.page);
    },
  },
};
</script>
<style>
.safety-banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/safety/banner-safety.jpg) top center no-repeat;
  margin-bottom: -32px;
}

/* 选项卡 */

.nav-pills.nav-green .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
}

.nav-pills.nav-green .nav-link:hover {
  color: #1cc579;
}

.nav-pills.nav-green .nav-link.active {
  background: linear-gradient(107deg, #2fe091 0%, #1cc579 99%);
  color: #fff;
  border: none;
}
nav {
  position: relative;
}
.search-box {
  top: 10px;
}
</style>
